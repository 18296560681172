import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import PlainHeader from "components/ui/base/PlainHeader";
import PricingComponent from "components/ui/extended/Pricing";
import Testimonials from "components/ui/extended/Testimonials";
import Customers from "components/ui/extended/Customers";
import ChatCTA from "components/ui/base/ChatCTA";

interface PricingPageProps {
  location: Location;
}

export default ({ location }: PricingPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.pricing.seo.title")}
        description={t("pages.pricing.seo.description")}
        image={{
          relativePath: "meta/customerly-live-chat.jpg",
          alt: "Customerly Pricing - Give your customers the support they deserve",
        }}
      />
      <PlainHeader
        title={t("pages.pricing.title")}
        description={t("pages.pricing.description")}
        maxWidth={900}
        ctaRegister={{ show: true }}
      />

      <PricingComponent location={location} />

      <Testimonials />

      <ChatCTA
        title={t("pages.pricing.chatCTATitle")}
        titleMaxWidth={500}
        chatMessage={t("pages.pricing.chatMessage")}
      />

      <Customers />
    </GlobalWrapper>
  );
};
